import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Blog from "./pages/Blog";
import Error from "./pages/Error";
import HomePage from "./pages/HomePage";
import SinglePost from "./pages/SinglePost";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage></HomePage>}></Route>
        <Route path="/aboutme" element={<Blog></Blog>}></Route>
        <Route
          path="/aboutme/:slug"
          element={<SinglePost></SinglePost>}
        ></Route>
        <Route path="*" element={<Error></Error>}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
