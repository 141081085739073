import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import client from "../client";
import BlockContent from "@sanity/block-content-to-react";
import Background from "../components/Background";

export default function SinglePost() {
  const [singlePost, setSinglePost] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { slug } = useParams();

  useEffect(() => {
    client
      .fetch(
        `*[slug.current == "${slug}"] {
        title,
        body,
        mainImage {
          asset -> {
            _id,
            url
          },
          alt
        }
      }`
      )
      .then((data) => setSinglePost(data[0]));
    setIsLoading(false);
  }, [slug]);

  return (
    <>
      {isLoading ? (
        <Background></Background>
      ) : (
        <section className="px-5 xl:max-w-6xl xl:mx-auto pb-20 tilt-in-fwd-tr">
          {singlePost.mainImage && singlePost.mainImage.asset && (
            <img
              src={singlePost.mainImage.asset.url}
              alt={singlePost.title}
              title={singlePost.title}
              className="my-card"
            />
          )}
          <h1 className="uppercase font-bold text-4xl tracking-wide mb-10 md:text-6xl lg:text-8xl text-center mt-5">
            {singlePost.title}
          </h1>
          <div className="block__content">
            <BlockContent
              blocks={singlePost.body}
              projectId=""
              dataset="production"
            />
          </div>
          <button>
            <button className="py-2 px-6 rounded shadow text-white bg-blue-400 hover:bg-blue-600 bordder-2 border-black transition-all duration-500  font-bold">
              <Link to="/aboutme">Go Back</Link>
            </button>
          </button>
        </section>
      )}
    </>
  );
}
