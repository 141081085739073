import React from "react";
import { Link } from "react-router-dom";
import Background from "../components/Background";
import Contact from "../components/Contact";
import Testimonials from "../components/Testimonials";

function HomePage() {
  return (
    <>
      <Background>
        <div>
          <section className="flex flex-col items-center justify-center text-center h-screen ">
            <img src="./portrait.png" className="portrait" alt="portrait"></img>
            <h1 className="tracking-in-expand uppercase font-bold text-5xl tracking-wide mb-2 md:text-6xl lg:text-8xl">
              Welcome
            </h1>
            <h1
              className="uppercase font-bold tracking-wide mb-3 md:text-1xl lg:text-2xl"
              id="delay"
            >
              I'm Daniel, a front end engineer/musician from Liverpool
            </h1>
            <button className="py-2 px-6 rounded shadow text-white bg-blue-400 hover:bg-blue-600 bordder-2 border-black transition-all duration-500  font-bold">
              <Link to="/aboutme">About Me</Link>
            </button>
            <div className="down-arrow">
              <a href="#contact">
                <div className="arrow"></div>
              </a>
            </div>
          </section>
        </div>
      </Background>
      {/* <Testimonials /> */}
      <Contact />
    </>
  );
}
export default HomePage;
