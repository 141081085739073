import { Link } from "react-router-dom";

function Error() {
  return (
    <div className="background">
      <section className="flex flex-col items-center justify-center text-center h-screen ">
        <img
          src="./r2d2.png"
          className="max-w-full and .h-auto mb-2 r2d2 wobble-hor-bottom"
        ></img>
        <h1 className="tracking-in-expand uppercase font-bold text-5xl tracking-wide mb-2 md:text-6xl lg:text-8xl">
          Whoops!
        </h1>
        <h1
          className="uppercase font-bold tracking-wide mb-3 md:text-1xl lg:text-2xl"
          id="delay"
        >
          404 - This is not the page you're looking for
        </h1>
        <button className="py-2 px-6 rounded shadow text-white bg-blue-400 hover:bg-blue-600 bordder-2 border-black transition-all duration-500  font-bold">
          <Link to="/">Go Home</Link>
        </button>
      </section>
    </div>
  );
}

export default Error;
