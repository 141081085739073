import React from "react";
import { Link } from "react-router-dom";

function Header() {
  return (
    <>
      <nav>
        <div class="logo">
          <h4>Daniel Doyle</h4>
        </div>
        <ul class="nav-links">
          <li>
            <a>
              <Link to="/">Home</Link>
            </a>
          </li>
          <li>
            <a>
              <Link to="/aboutme">About Me</Link>
            </a>
          </li>
        </ul>
        <div class="burger">
          <div class="line1"></div>
          <div class="line2"></div>
          <div class="line3"></div>
        </div>
      </nav>
    </>
  );
}

// function navSlide() {
//   const burger = document.querySelector(".burger");
//   const nav = document.querySelector(".nav-links");
//   const navLinks = document.querySelectorAll(".nav-links li");

//   burger.addEventListener("click", () => {
//     nav.classList.toggle("nav-active");

//     navLinks.forEach((link, index) => {
//       if (link.style.animation) {
//         link.style.animation = "";
//       } else {
//         link.style.animation = `navLinkFade 0.3s ease forwards ${
//           index / 7 + 0.3
//         }s`;
//       }
//     });
//     burger.classList.toggle("toggle");
//   });
// }

// navSlide();

export default Header;
