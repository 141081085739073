import { useState, useEffect } from "react";
import client from "../client";
import { Link } from "react-router-dom";
import Background from "../components/Background";

function Blog() {
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    client
      .fetch(
        `*[_type == "post"]{
             title, 
             body,
             slug,
             mainImage{
                 asset -> {
                    _id,
                    url
                 },
                 alt
             },
             description,
             publishedAt,
             blogCategory,
          }`
      )
      .then((data) => {
        return setPosts(data);
      })
      .catch(console.error);
  }, []);
  return (
    <>
      <Background>
        <div>
          <section className="flex flex-col items-center justify-center text-center h-screen m-2">
            <div className="flex">
              <h3 className="tracking-in-expand uppercase font-bold text-4xl tracking-wide mb-2 md:text-4xl lg:text-7xl mr-2 rotate-in-center">
                &#9775;
              </h3>
              <h1 className="tracking-in-expand uppercase font-bold text-4xl tracking-wide mb-2 md:text-4xl lg:text-7xl ">
                About Me
              </h1>
            </div>
            <div className="mb-2 my-container px-4 tilt-in-fwd-tr ">
              <h5 class="font-bold leading-tight text-sm lg:text-lg mt-0 mb-2">
                <em className="bg-blue-300 rounded p-2 text-sm lg">
                  Early Life &#128396;
                </em>
              </h5>
              <p className="text-xs md:text-md lg:text-lg   mb-5">
                Having went through most of my life playing in bands across the
                country, and most of my early years with my head in an art book,
                I knew from a very early age that I wanted in some way to leave
                my mark on the creative space.
              </p>
              <h5 class="font-bold leading-tight text-sm lg:text-l mt-0 mb-2">
                <em className="bg-red-300 rounded p-2">Music &#127928;</em>
              </h5>
              <p className="text-xs md:text-md lg:text-lg   mb-5">
                I've played guitar since the age of 12 <em>...wow</em>, as of
                writing this I'm now 23 and have gigged and recorded enough to
                consider myself experienced in the field of music (though I am
                always learning of course). My influences vary, anything from
                Slash, to Larry Carlton, or Ariel Posen of the Bros. Landreth. I
                just &hearts; music.
              </p>
              <h5 class="font-bold leading-tight text-sm lg:text-l mt-0 mb-2">
                <em className="bg-green-300 rounded p-2 mb-2">
                  Web Development &#10024;
                </em>
              </h5>
              <p className="text-xs md:text-md lg:text-lg   mb-5">
                I mentioned before that I am deeply passionate about creating,
                web development allows me to bring my creative pen to a digital
                canvas, and there's no better feeling than typing pig latin onto
                a screen and winding up with something beautiful!
              </p>
            </div>
          </section>
        </div>
      </Background>

      <div class="grid md:grid-cols-2 lg:grid-cols-3 container mx-auto">
        <div className="max-w-sm h-auto mx-auto my-20 rounded overflow-hidden shadow-lg bg-gray-900">
          <a href="https://stokware.co.uk">
            <img className="object-cover h-48 w-96" src="/stokware.png" />
          </a>
          <div className="px-6 py-4 bg-slate-700 m-2">
            <div className="font-bold text-xl mb-2 hover:text-gray-500 hover:cursor-pointer">
              <a href="https://stokware.co.uk">StokWare</a>
            </div>
            <div className="flex">
              <em className="text-white mb px-2 bg-green-600 rounded">C#</em>
              <em className="text-white mb px-2 bg-green-300 rounded ml-2">
                .NET
              </em>
              <em className="text-white mb px-2 bg-blue-300 rounded ml-2">
                Blazor
              </em>
              <em className="text-white mb px-2 bg-gray-500 rounded ml-2">
                Xamarin
              </em>
            </div>
            <p className=" mt-2 text-gray-200 text-xs md:text-md lg:text-md">
              RFIDiom's flagship application built to help businesses manage
              their inventory through the use of sensor based technologies. I
              architectured the front end web/UI structure, and built the mobile
              appplication using xamarin forms interfacing with bespoke
              bluetooth and reader commands.
            </p>
          </div>
        </div>

        <div className="max-w-sm h-auto mx-auto my-20 rounded overflow-hidden shadow-lg bg-gray-900">
          <a href="http://rfidiomtechtalk.netlify.app">
            <img className="object-cover h-48 w-96" src="/techtalk.png" />
          </a>
          <div className="px-6 py-4 bg-slate-700 m-2">
            <div className="font-bold text-xl mb-2 hover:text-gray-500 hover:cursor-pointer">
              <a href="http://rfidiomtechtalk.netlify.app">Tech Talk (WIP)</a>
            </div>
            <div className="flex">
              <em className="text-white mb px-2 rounded bg-red-300">React</em>
              <em className="ml-2 text-white mb px-2 rounded bg-yellow-200">
                Sanity CMS
              </em>
            </div>
            <p className=" mt-2 text-gray-200 text-xs md:text-md lg:text-md">
              A solo project blog site built for RFIDiom Ltd. The goal of which
              was to expand the marketing department capabilities.
            </p>
          </div>
        </div>

        <div className="max-w-sm h-auto mx-auto my-20 rounded overflow-hidden shadow-lg bg-gray-900">
          <a href="https://aspireeventware.co.uk">
            <img className="object-cover h-48 w-96" src="/aspire.png" />
          </a>
          <div className="px-6 py-4 bg-slate-700 m-2">
            <div className="font-bold text-xl mb-2 hover:text-gray-500 hover:cursor-pointer">
              <a href="https://aspireeventware.co.uk">Aspire Eventware</a>
            </div>
            <div className="flex">
              <em className="text-white mb px-2 bg-green-600 rounded">C#</em>
              <em className="text-white mb px-2 bg-green-300 rounded ml-2">
                .NET
              </em>
              <em className="text-white mb px-2 bg-blue-300 rounded ml-2">
                MVC
              </em>
              <em className="text-white mb px-2 bg-gray-500 rounded ml-2">
                Xamarin
              </em>
              <em className="text-white mb px-2 bg-indigo-600 rounded ml-2">
                Bootstrap
              </em>
            </div>
            <p className="mt-2 text-gray-200 text-xs md:text-md lg:text-md">
              Enterprise level, RFID powered event management software.
            </p>
          </div>
        </div>

        <div className="max-w-sm h-auto mx-auto my-20 rounded overflow-hidden shadow-lg bg-gray-900">
          <img className="object-cover h-48 w-96" src="/whiskeythrills.png" />
          <div className="px-6 py-4 bg-slate-700 m-2">
            <div className="font-bold text-xl mb-2 hover:text-gray-500 hover:cursor-pointer">
              <a href="/">The Whiskey Thrills</a>
            </div>
            <div className="flex">
              <em className="text-white mb px-2 rounded bg-red-300">React</em>
              <em className="ml-2 text-white mb px-2 rounded bg-yellow-200">
                Sanity CMS
              </em>
            </div>
            <p className=" mt-2 text-gray-200 text-xs md:text-md lg:text-md">
              A website built for my band by me! It was built using the latest
              technologies in order to maximise the sites potential and to
              ensure the other members could make changes to the site with ease.
            </p>
          </div>
        </div>

        <div className="max-w-sm h-auto mx-auto my-20 rounded overflow-hidden shadow-lg bg-gray-900">
          <img className="object-cover h-48 w-96" src="/pupstagram.png" />
          <div className="px-6 py-4 bg-slate-700 m-2">
            <div className="font-bold text-xl mb-2 hover:text-gray-500 hover:cursor-pointer">
              <a href="/">Pupstagram</a>
            </div>
            <div className="flex">
              <em className="text-white mb px-2 rounded bg-red-300">React</em>
              <em className="text-white mb px-2 bg-indigo-600 rounded ml-2">
                Tailwind CSS
              </em>
            </div>
            <p className=" mt-2 text-gray-200 text-xs md:text-md lg:text-md">
              This is what happens when you come across a perfectly good API and
              you have a few hours to kill. Also... dogs.
            </p>
          </div>
        </div>

        <div className="max-w-sm h-auto mx-auto my-20 rounded overflow-hidden shadow-lg bg-gray-900">
          <a href="http://daniel-doyle.epizy.com">
            <img className="object-cover h-48 w-96" src="/firstsite.png" />
          </a>
          <div className="px-6 py-4 bg-slate-700 m-2">
            <div className="font-bold text-xl mb-2 hover:text-gray-500 hover:cursor-pointer">
              <a href="http://daniel-doyle.epizy.com">My First Website</a>
            </div>
            <div className="flex">
              <em className="text-white mb px-2 rounded bg-red-300">HTML</em>
              <em className="text-white mb px-2 rounded bg-red-400 ml-2">JS</em>
              <em className="text-white mb px-2 bg-indigo-600 rounded ml-2">
                Bootstrap
              </em>
            </div>
            <p className=" mt-2 text-gray-200 text-xs md:text-md lg:text-md">
              My first website, built in 2019. It was built using HTML, a mixure
              of Bootstrap and plain CSS, and JS.
            </p>
          </div>
        </div>
      </div>

      {/* <div class="grid md:grid-cols-2 lg:grid-cols-3 container mx-auto">
        {posts.map((post) => (
          <div
            className="max-w-sm h-auto mx-auto my-20 rounded overflow-hidden shadow-lg bg-gray-900"
            key={post.slug.current}
          >
            <Link to={post.slug.current}>
              <img
                className="object-cover h-48 w-96"
                src={post.mainImage.asset.url}
                alt={post.title}
              />
            </Link>
            <div className="px-6 py-4 bg-slate-700 m-2">
              <div className="font-bold text-xl mb-2 hover:text-gray-500 hover:cursor-pointer">
                <Link to={post.slug.current}>{post.title}</Link>
              </div>
              <div className="flex">
                <em className="text-white mb px-2 rounded bg-blue-300">
                  {post.blogCategory}
                </em>
              </div>
              <p className="text-gray-200 text-xs md:text-md lg:text-md mt-2">
                {post.description}
              </p>
            </div>
          </div>
        ))}
      </div> */}
    </>
  );
}

export default Blog;
